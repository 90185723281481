import {
  Margin,
  Small,
  Heading3,
  Row,
  Card,
  Strong,
  TextLink,
} from '@ovotech/nebula';
import { AnalyticsClick, useAccountContext } from '@ovotech/ui-tools';
import { differenceInCalendarMonths, format } from 'date-fns';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useHistory } from 'react-router-dom-v5';
import { SimplePageHeader } from '@/src/components/Pages/SimplePageHeader';
import { EVAnytimeTile } from '../components/EVAnytime/EVAnytimeTile';
import { EVInsightsAndSavingsTile } from '../components/EVInsights/EVInsightsAndSavingsTile';
import { EVNewsAndOffersTile } from '../components/EVNews/EVNewsAndOffersTile';
import { CollectingSmartChargerData } from '../components/EVOnboarding/CollectingSmartChargerData';
import { ConnectSmartChargerError } from '../components/EVOnboarding/ConnectSmartChargerError';
import { ElectricUsageChartMonthly } from '../components/ElectricUsageChart/ElectricUsageChartMonthly';
import { Feedback } from '../components/Feedback/Feedback';
import GreenCard from '../components/GreenCard/GreenCard';
import MonthlyNavigator from '../components/MonthlyNavigator/MonthlyNavigator';

import { useKaluzaSmartCharger } from '../../AnytimeEligibility/hooks/useKaluzaSmartCharger';
import { getDateFromParams, defaultDateRedirectPath } from '../utils';
import { StyledCol } from './EvInlifePage.styled';
import { useAnytimeCreditsQuery } from '@/src/api';
import { PageWithNavigation, ScrollToTopOnMount } from '@/src/components';
import {
  EVS_INLIFE_COLLECTING_MONTHLY_DATA,
  EVS_INLIFE_DISCONNECT_LINK,
  EVS_INLIFE_MONTHLY,
  EVS_INLIFE_MONTHLY_ERROR,
  EVS_INLIFE_MONTHLY_NO_CONNECTION,
} from '@/src/constants/analytics-subjects';
import { ROUTE_EV_DISCONNECT } from '@/src/constants/routes';
import { getProductsStart } from '@/src/redux/ducks/products/products';
import { MonthYear, State } from '@/src/types/State';
import { useChargeAnytimeBoltonState } from '../../AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

const EV_PRODUCTS_SURVEY = 'ev-products';
const EV_MORE_INFO_SURVEY = 'ev-more-info';

export const EVsInlifePageMonthly = () => {
  const reduxDispatch = useDispatch();
  const activatedProducts = useSelector(
    (state: State) => state.products.activated,
  );
  useEffect(() => {
    if (
      !activatedProducts.data &&
      !activatedProducts.isFetching &&
      !activatedProducts.errorResponse
    ) {
      reduxDispatch(getProductsStart());
    }
  });

  const { year, month } = useParams<{ year: string; month: string }>();
  const dateNavigatedTo = getDateFromParams(month, year);
  const { connection, error, isFetching, hasConnectionCode } =
    useKaluzaSmartCharger();

  const FNS_MONTH_INDEX_CORRECTION = 1;
  const convertMonthYearToDateObj = (date: MonthYear) =>
    new Date(date.year, date.month - FNS_MONTH_INDEX_CORRECTION, 1);
  const { accountId } = useAccountContext();

  const { anytimeBoltonActive, anytimeBoltonActiveFrom, anytimeBolton } =
    useChargeAnytimeBoltonState();

  const dateObj = convertMonthYearToDateObj(dateNavigatedTo);
  const currentDateObj = new Date();
  const differenceInMonths = differenceInCalendarMonths(
    currentDateObj,
    dateObj,
  );
  const anytimeCurrentDate = format(currentDateObj, 'YYYY/MM');
  const [anytimeCurrentDateYear, anytimeCurrentDateMonth] =
    anytimeCurrentDate.split('/');
  const anytimeQueryRunCondition =
    anytimeBoltonActive &&
    Boolean(connection) &&
    Boolean(anytimeBolton?.activeFrom);

  const dateInvalid = () => {
    if (anytimeBoltonActive) {
      if (
        anytimeBoltonActiveFrom &&
        currentDateObj.getTime() <= anytimeBoltonActiveFrom.getTime()
      ) {
        return true;
      }

      if (differenceInMonths > 12) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const anytimeResponse = useAnytimeCreditsQuery(
    accountId,
    anytimeBolton?.activeFrom,
    anytimeCurrentDateYear,
    anytimeCurrentDateMonth,
    anytimeQueryRunCondition,
  );

  const anytimeSavings =
    anytimeResponse.status === 'data' ? anytimeResponse.data : null;

  const currentRate = anytimeSavings?.tariffRateGBPPerKWH;
  const history = useHistory();

  useEffect(() => {
    if (dateInvalid()) {
      history.push(defaultDateRedirectPath('/my-ev', new Date()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const isConnectingToKaluza = hasConnectionCode && !connection && !error;

  if (error) {
    return (
      <PageWithNavigation
        analytics={{ name: EVS_INLIFE_MONTHLY_ERROR }}
        title="Your EV - Monthly"
      >
        <>
          <SimplePageHeader
            title="Your EV"
            subTitle="Amazing insights, exclusive offers, and the latest news – tailored to you"
          />
          <ConnectSmartChargerError />
          <EVNewsAndOffersTile />
          <Margin top={4}>
            <Small>
              Your insights are just estimates. They’re based on the info your
              smart charger is sending us.
            </Small>
          </Margin>
        </>
      </PageWithNavigation>
    );
  }

  if (isFetching || isConnectingToKaluza) {
    return (
      <PageWithNavigation
        analytics={{ name: EVS_INLIFE_COLLECTING_MONTHLY_DATA }}
        title="Your EV - Monthly"
      >
        <SimplePageHeader
          title="Your EV"
          subTitle="Amazing insights, exclusive offers, and the latest news – tailored to you"
        />
        <CollectingSmartChargerData />
      </PageWithNavigation>
    );
  } else if (connection) {
    return (
      <PageWithNavigation
        analytics={{ name: EVS_INLIFE_MONTHLY }}
        title="Your EV - Monthly"
      >
        <>
          <ScrollToTopOnMount />
          <SimplePageHeader
            title="Your EV"
            subTitle="Amazing insights, exclusive offers, and the latest news – tailored to you"
          />
          <GreenCard />
          <Margin top={8}>
            <Heading3>The electricity costs of your home and car</Heading3>
            <MonthlyNavigator dateNavigatedTo={dateNavigatedTo} />
            <ElectricUsageChartMonthly dateNavigatedTo={dateNavigatedTo} />
            <Margin top={8}>
              {anytimeBoltonActive && currentRate ? (
                <Row isNested>
                  <StyledCol medium={6}>
                    <EVAnytimeTile />
                  </StyledCol>
                  <StyledCol medium={6}>
                    <EVInsightsAndSavingsTile />
                  </StyledCol>
                </Row>
              ) : (
                <EVInsightsAndSavingsTile />
              )}
            </Margin>
            <Feedback surveyId={EV_MORE_INFO_SURVEY}>
              Want to know more about your EV charging? We’re listening.
            </Feedback>
            <EVNewsAndOffersTile />
            <Feedback surveyId={EV_PRODUCTS_SURVEY}>
              Interested in getting more EV products? Just tell us which.
            </Feedback>
            {!anytimeBoltonActive && (
              <AnalyticsClick name={EVS_INLIFE_DISCONNECT_LINK}>
                <Margin top={3} bottom={3}>
                  <Card>
                    <Strong>Don’t want to get EV insights anymore?</Strong> No
                    problem. Visit{' '}
                    <TextLink
                      to={ROUTE_EV_DISCONNECT}
                      as={Link}
                      data-testid="disconnect-smart-charger"
                    >
                      this page
                    </TextLink>{' '}
                    and we’ll sort it.
                  </Card>
                </Margin>
              </AnalyticsClick>
            )}

            <Small>
              Your insights are just estimates. They’re based on the info your
              smart charger is sending us.
            </Small>
          </Margin>
        </>
      </PageWithNavigation>
    );
  } else {
    return (
      <PageWithNavigation
        title="Your EV"
        analytics={{ name: EVS_INLIFE_MONTHLY_NO_CONNECTION }}
      >
        <>
          <SimplePageHeader
            title="Your EV"
            subTitle="Amazing insights, exclusive offers, and the latest news – tailored to you"
          />
        </>
      </PageWithNavigation>
    );
  }
};
