import {
  CTAButton,
  Disclosure,
  Field,
  Heading2,
  Li,
  Margin,
  P,
  SecondaryCTAButton,
  SkeletonHeading,
  TextField,
  TextLink,
  Ul,
} from '@ovotech/nebula';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom-v5';
import { PageHeader } from '../components/PageHeader';
import {
  LoadingStyledCard,
  PromoContainer,
  StyledTermsCheckbox,
  StyledUl,
  ButtonGroupWrapper,
  Divider,
} from './DataSharingAndTCConsent.styled';
import { PageWithNavigation } from '@/src/components';
import { ButtonGroup } from '@/src/components/ButtonGroup/ButtonGroup';
import { EV } from '@/src/constants/analytics';
import {
  CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK,
  KALUZA_PRIVACY_POLICY_WEB_LINK,
  OVO_PRIVACY_POLICY_LINK,
} from '@/src/constants/links';
import { usePayAsYouDriveProduct } from '@/src/pages/AnytimeEligibility/hooks/usePayAsYouDriveProduct';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { TechnicalGlitch } from '@/src/pages/AnytimeEligibility/components/TechnicalGlitch';
import { ChargeAnytimeAppLink } from '@/src/pages/AnytimeEligibility/pages/steps/ChargeAnytimeAppLink';
import { useAvailableProductsQuery } from '@/src/api';
import {
  badRequestErrors,
  eligibleForVWGPromo,
  isServerError,
  getIntegrationType,
  getVolvoTrackingEventName,
  isVWGOrderNumberValid,
  PromoType,
  VOLVO_PROMO_FORM,
  VWG_PROMO_COPY,
  VWG_PROMO_FORM,
  VWG_PROMO_HEADING,
  ChargeAnytimeErrorResponse,
  useChargeAnytimeContext,
} from '@monovo/ev-anytime';
import { PRODUCT_IDS } from '@/src/constants/products';
import { VolvoAnytimeAppLink } from '@/src/pages/AnytimeEligibility/pages/steps/VolvoAnytimeAppLink';
import { useFeature } from '@/src/utils/optimizely';
import {
  CHARGE_ANYTIME_API,
  INDRA_CHARGE_ANYTIME_APP_ENABLED,
  PAY_AS_YOU_DRIVE_VWG,
  VWG_ORDER_NUMBER_FIELD,
} from '@/src/constants/feature-flags';
import { useChargeAnytimeAcquisitionMutation } from '@/src/api/use-charge-anytime-acquisition-mutation';
import { AxiosError } from 'axios';

export const DataSharingAndTCConsent = () => {
  const { dispatch: analyticsDispatch } = useAnalytics();
  const [consentShareEVData, setConsentShareEVData] = useState(false);
  const [consentCATerms, setConsentCATerms] = useState(false);
  const [consentErrors, setConsentErrors] = useState(false);
  const [showDownloadPage, setShowDownloadPage] = useState(false);
  const [showVolvoLinkPage, setShowVolvoLinkPage] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [vwgOrderNumber, setVWGOrderNumber] = useState('');
  const [vwgOrderNumberError, setVWGOrderNumberError] = useState('');
  const [validPromoCode, setValidPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState(false);
  const { activateProduct, isReady, productActivationMutation } =
    usePayAsYouDriveProduct();
  const acquisitionMutation = useChargeAnytimeAcquisitionMutation();
  const { isError, error } = acquisitionMutation;
  const { refetch, isLoading } = useAvailableProductsQuery(
    PRODUCT_IDS.payasyoudrive,
    null,
    promoCode,
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const [payAsYouDriveVWGEnabled] = useFeature(PAY_AS_YOU_DRIVE_VWG);
  const [isVWGOrderNumberFieldEnabled] = useFeature(VWG_ORDER_NUMBER_FIELD);
  const [isChargeAnytimeApiEnabled] = useFeature(CHARGE_ANYTIME_API);
  const [isIndraChargeAnytimeAppEnabled] = useFeature(
    INDRA_CHARGE_ANYTIME_APP_ENABLED,
  );
  const history = useHistory();

  const isChargeAnytimeGlitch =
    isChargeAnytimeApiEnabled && isError && isServerError(error);

  const {
    smartCharger,
    make,
    model,
    year,
    hasSolarPanels,
    hasInternetAccess,
    isEligibleVolvo,
    isPayAsYouDriveVolvoEnabled,
  } = useChargeAnytimeContext();

  const integrationType = getIntegrationType({
    charger: smartCharger,
    vehicle: { make, model, year },
    hasSolar: !!hasSolarPanels,
    hasVehicleInternet: !!hasInternetAccess,
    featureFlags: {
      payAsYouDriveVolvo: isPayAsYouDriveVolvoEnabled,
      isIndraChargeAnytimeApp: isIndraChargeAnytimeAppEnabled,
    },
  });

  const changePromoCode: React.ChangeEventHandler<HTMLInputElement> = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPromoCode(e.target.value);
  };

  const changeConsentShareEVData = () => {
    setConsentShareEVData(!consentShareEVData);
  };

  const changeConsentCATerms = () => {
    setConsentCATerms(!consentCATerms);
  };

  const isEligibleVWG =
    payAsYouDriveVWGEnabled && eligibleForVWGPromo(make, model, year);

  const shouldSendVwgOrderNumber =
    isVWGOrderNumberFieldEnabled &&
    isEligibleVWG === PromoType.CAN_LINK_TO_VW_PROMO;

  const validateVwgOrderNumber = () => {
    if (!shouldSendVwgOrderNumber) {
      return true;
    }
    return isVWGOrderNumberValid(vwgOrderNumber);
  };

  const validatePromoCode = async () => {
    if (promoCode) {
      const checkPromoCode = await refetch();
      if (checkPromoCode.isError) {
        setPromoCodeError(true);
      } else {
        setPromoCodeError(false);
        setValidPromoCode(promoCode);
      }
    } else {
      setPromoCodeError(true);
    }
  };

  const sendVolvoTracking = () => {
    const eventName = getVolvoTrackingEventName(
      isEligibleVolvo,
      !!hasSolarPanels,
      smartCharger,
      validPromoCode,
    );
    if (eventName) {
      analyticsDispatch({
        type: 'view',
        name: eventName,
      });
    }
  };

  const confirmConsent = async () => {
    const isVWGOrderNumberValid = validateVwgOrderNumber();
    if (!isVWGOrderNumberValid) {
      setVWGOrderNumberError('Enter your 8 digit order number');
    }
    if (consentShareEVData && consentCATerms && isVWGOrderNumberValid) {
      sendVolvoTracking();
      const vwgOrderNumberProps = shouldSendVwgOrderNumber
        ? { vwgOrderNumber }
        : {};
      analyticsDispatch({
        type: 'click',
        name: EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED,
        properties: {
          chargerName: smartCharger,
          carMake: make,
          carModel: model,
          carYear: year,
          internetAccess: hasInternetAccess,
          solarPanels: hasSolarPanels,
          isEligibleVolvo: isEligibleVolvo,
          validPromoCode: validPromoCode,
          ...vwgOrderNumberProps,
        },
      });
      analyticsDispatch({
        type: 'click',
        name: 'EVS - EVS OVO Drive Anytime consent yes clicked',
        properties: {
          chargerName: smartCharger,
          carMake: make,
          carModel: model,
          carYear: year,
          internetAccess: hasInternetAccess,
          solarPanels: hasSolarPanels,
          isEligibleVolvo: isEligibleVolvo,
          validPromoCode: validPromoCode,
          ...vwgOrderNumberProps,
        },
      });

      if (isChargeAnytimeApiEnabled) {
        try {
          await acquisitionMutation.mutateAsync({
            charger: smartCharger,
            make: make!,
            model,
            year,
            hasInternetAccess: !!hasInternetAccess,
            hasSolarPanels: !!hasSolarPanels,
            ...vwgOrderNumberProps,
          });
        } catch (error) {
          const { status, response } =
            error as AxiosError<ChargeAnytimeErrorResponse>;
          if (status === 400) {
            const errors = badRequestErrors(response?.data ?? []) as {
              vwgOrderNumber: string;
            };
            setVWGOrderNumberError(errors.vwgOrderNumber);
          }
          return;
        }
      }
      if (integrationType === 'Volvo') {
        try {
          await activateProduct(validPromoCode);
          setConsentErrors(false);
          setShowVolvoLinkPage(true);
        } catch {
          // Handled by productActivationMutation.isError below
          analyticsDispatch({
            type: 'view',
            name: EV.ELIGIBILITY
              .EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_TECHNICAL_GLITCH_VIEWED,
          });
        }
      } else if (integrationType === 'Ohme') {
        window.location.assign(
          process.env.NX_EV_ANYTIME_OHME_IDENTITY_URL as string,
        );
      } else if (integrationType === 'Indra') {
        window.location.assign(
          process.env.NX_EV_ANYTIME_KALUZA_IDENTITY_URL as string,
        );
      } /* KALUZA APP JOURNEY */ else {
        setConsentErrors(false);
        setShowDownloadPage(true);
      }
    } else {
      setConsentShareEVData(consentShareEVData);
      setConsentCATerms(consentCATerms);
      setConsentErrors(true);
    }
  };

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_CONSENT_VIEWED,
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: 'EVS - EVS OVO Charge Anytime D2V Confirmation Eligibility Consent Viewed',
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !isReady ||
    productActivationMutation.isLoading ||
    acquisitionMutation.isLoading
  ) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <SkeletonCard />
      </PageWithNavigation>
    );
  }

  if (productActivationMutation.isError || isChargeAnytimeGlitch) {
    return (
      <PageWithNavigation title="Charge Anytime">
        <TechnicalGlitch />
      </PageWithNavigation>
    );
  }

  return showVolvoLinkPage ? (
    <PageWithNavigation title="Charge Anytime">
      <PageHeader
        data-testid="success-message"
        title={'Success! You can get Charge Anytime.'}
        showDescription={false}
      />
      <VolvoAnytimeAppLink />
    </PageWithNavigation>
  ) : showDownloadPage ? (
    <PageWithNavigation title="Charge Anytime">
      <PageHeader
        data-testid="success-message"
        title={'Success! You can get Charge Anytime.'}
        showDescription={false}
      />
      <ChargeAnytimeAppLink />
    </PageWithNavigation>
  ) : (
    <PageWithNavigation title="Charge Anytime">
      <PageHeader
        title={'Success! You can get Charge Anytime.'}
        showDescription={false}
      />
      <LoadingStyledCard data-testid="anytime-consent-page">
        <Heading2>Data sharing</Heading2>
        <P>
          To bring you Charge Anytime, we need to share the following data with
          our trusted tech partner, Kaluza, and your EV charging partner:
        </P>
        <Ul>
          <Li>Your EV charger ID</Li>
          <Li>Your EV details</Li>
          <Li>Your EV energy use</Li>
        </Ul>
        <Margin top={4}>
          <StyledTermsCheckbox
            id="dataSharingEVData"
            label="I agree for my EV data to be shared with OVO, Kaluza and my EV charging partner. "
            checked={consentShareEVData}
            onChange={changeConsentShareEVData}
            error={
              !consentShareEVData &&
              consentErrors &&
              'Please tick ok to continue'
            }
            data-testid="dataSharingEVData"
          />
        </Margin>
        <Margin top={4}>
          <Disclosure name="more-about-data" title="More about data sharing">
            <P>
              We share your data with Kaluza and your charging partner (this is
              your EV charger manufacturer or your EV manufacturer). We do this
              to work out the most accurate charging costs for you. Find out
              exactly what information will be shared using the links below.
            </P>
            <StyledUl>
              <Li>
                <TextLink
                  href={KALUZA_PRIVACY_POLICY_WEB_LINK}
                  opensInNewWindow
                >
                  See Kaluza Privacy Policy
                </TextLink>
              </Li>
              <Li>
                <TextLink href={OVO_PRIVACY_POLICY_LINK} opensInNewWindow>
                  See OVO Privacy Policy
                </TextLink>
              </Li>
            </StyledUl>
          </Disclosure>
        </Margin>
        <Heading2>Our terms and conditions</Heading2>
        <P>
          To add Charge Anytime you’ll need to agree to our terms and
          conditions.
        </P>
        <StyledUl>
          <Li>
            <TextLink
              href={CHARGE_ANYTIME_TERMS_AND_CONDITIONS_LINK}
              opensInNewWindow
            >
              Charge Anytime terms and conditions
            </TextLink>
          </Li>
        </StyledUl>
        <Margin top={4} bottom={8}>
          <StyledTermsCheckbox
            id="chargeAnytimeTerms"
            label="I agree to the Charge Anytime terms and conditions."
            checked={consentCATerms}
            onChange={changeConsentCATerms}
            error={
              !consentCATerms && consentErrors && 'Please tick ok to continue'
            }
            data-testid="chargeAnytimeTerms"
          />
        </Margin>
        {isEligibleVolvo === PromoType.CAN_ADD_VOLVO_PROMO &&
        !validPromoCode &&
        !isLoading ? (
          <Margin top={4} bottom={8}>
            <Heading2>Do you have a promo code?</Heading2>
            <Field
              id="chargeAnytimePromoCodeContainer"
              label=""
              error={
                promoCodeError &&
                'We don’t recognise that promo code. Please check and try again.'
              }
            >
              <PromoContainer>
                <TextField
                  id="chargeAnytimePromoCode"
                  placeholder="Enter your code here"
                  data-testid="chargeAnytimePromoCodeInput"
                  label=""
                  value={promoCode}
                  onChange={changePromoCode}
                />
                <AnalyticsClick
                  name={
                    EV.ELIGIBILITY
                      .EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_CODE_CLICKED
                  }
                >
                  <CTAButton
                    onClick={validatePromoCode}
                    data-testid="changePromoCodeButton"
                  >
                    Apply
                  </CTAButton>
                </AnalyticsClick>
              </PromoContainer>
            </Field>
          </Margin>
        ) : isEligibleVolvo === PromoType.CAN_LINK_TO_VOLVO_PROMO &&
          !validPromoCode &&
          !isLoading ? (
          <Margin top={4} bottom={8}>
            <Heading2>Do you have a promo code?</Heading2>
            <StyledUl>
              <Li>
                <AnalyticsClick
                  name={
                    EV.ELIGIBILITY
                      .EVS_OVO_CHARGE_ANYTIME_VOLVO_PROMO_FORM_CLICKED
                  }
                >
                  <TextLink href={VOLVO_PROMO_FORM} opensInNewWindow>
                    Click here to send us your code
                  </TextLink>
                </AnalyticsClick>
              </Li>
            </StyledUl>
          </Margin>
        ) : null}
        {isLoading ? (
          <SkeletonHeading headingComponent={Heading2} size="90%" />
        ) : validPromoCode ? (
          <Margin top={4} bottom={8}>
            <Heading2>Thanks!</Heading2>
            <P>
              After you finish signing up for Charge Anytime in the app, we'll
              activate the promotion for you.
            </P>
          </Margin>
        ) : null}
        {isEligibleVWG === PromoType.CAN_LINK_TO_VW_PROMO && (
          <>
            <Divider />
            <Margin bottom={8}>
              <Heading2>{VWG_PROMO_HEADING}</Heading2>
              <P>{VWG_PROMO_COPY}</P>
              <StyledUl>
                <Li>
                  <AnalyticsClick
                    name={
                      EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_VW_PROMO_CLICKED
                    }
                    properties={{
                      smartChargerSelection: smartCharger,
                      validPromoCode: validPromoCode,
                      isEligibleVWG: isEligibleVWG,
                      carMake: make,
                      carModel: model,
                      carYear: year,
                      solarPanels: hasSolarPanels,
                      internetAccess: hasInternetAccess,
                    }}
                  >
                    {!isVWGOrderNumberFieldEnabled ? (
                      <TextLink
                        data-event-name={
                          'EVS - EVS OVO Drive Anytime VW promo clicked'
                        }
                        data-event-props={JSON.stringify({
                          smartChargerSelection: smartCharger,
                          validPromoCode: validPromoCode,
                          isEligibleVWG: isEligibleVWG,
                          carMake: make,
                          carModel: model,
                          carYear: year,
                          solarPanels: hasSolarPanels,
                          internetAccess: hasInternetAccess,
                        })}
                        href={VWG_PROMO_FORM}
                        opensInNewWindow
                      >
                        Click here to send us your order number
                      </TextLink>
                    ) : (
                      <TextField
                        id="chargeAnytimeVWGOrderNUmber"
                        placeholder="Enter your order number here"
                        data-testid="chargeAnytimeVWGOrderNumber"
                        label=""
                        error={!!vwgOrderNumberError}
                        hint={vwgOrderNumberError}
                        value={vwgOrderNumber}
                        onChange={e => {
                          setVWGOrderNumberError('');
                          setVWGOrderNumber(e.target.value);
                        }}
                      />
                    )}
                  </AnalyticsClick>
                </Li>
              </StyledUl>
            </Margin>
            <Divider />
          </>
        )}
        <ButtonGroupWrapper>
          <ButtonGroup>
            <AnalyticsClick
              name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_BACK_CLICKED}
            >
              <SecondaryCTAButton
                iconLeft="chevron-left"
                iconRight="off"
                onClick={history.goBack}
                data-testid="backButton"
              >
                Back
              </SecondaryCTAButton>
            </AnalyticsClick>
            <AnalyticsClick
              name={EV.SIGNUP.EVS_OVO_CHARGE_ANYTIME_CONSENT_CONFIRM_CLICKED}
            >
              <CTAButton
                data-event-name={
                  'EVS - EVS OVO Drive Anytime consent yes clicked'
                }
                onClick={confirmConsent}
                data-testid="confirmConsentButton"
              >
                Confirm
              </CTAButton>
            </AnalyticsClick>
          </ButtonGroup>
        </ButtonGroupWrapper>
      </LoadingStyledCard>
    </PageWithNavigation>
  );
};
