import { Callout, Heading2, Margin, P } from '@ovotech/nebula';
import { useAnalytics } from '@ovotech/ui-tools';
import React, { createRef, useState, useEffect } from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import {
  PowerMoveErrorPage,
  PowerMoveMonthChallenge,
  PowerMoveDescriptionAccordion,
  PowerMoveEligibilityCard,
  PowerMoveHeroBanner,
  SectionsHorizontalDivider,
  PowerMoveChallengeSuccessfullySignedUpModal,
  HowRewardsAreCalculatedModal,
  PowerMoveChallengeOptOutModal,
  NavigationBreadCrumb,
} from './components';
import { NoHalfHourlyReadingsModal } from './components/NoHalfHourlyReadingsModal';
import { usePowerMoveChallenges } from './hooks/use-power-move-challenges';
import { usePowerMoveEligibility } from './hooks/use-power-move-eligibility';
import { MappedPowerMoveChallenge } from './types';
import { PWR_MV_FEATURE_OFF } from './types/errorMessages';
import { UsePowerMoveEligibilityReturn } from './types/powerMoveEligibility';
import { usePowerMoveChallengeSignupMutation } from '@/src/api';
import { PageWithNavigation } from '@/src/components';
import { PATH_TO_ZERO } from '@/src/constants/analytics';
import { SHOW_POWER_MOVE } from '@/src/constants/feature-flags';
import { breakpoints } from '@/src/utils/mediaQuery';
import { useDecision } from '@/src/utils/optimizely';
import { PowerMoveTips } from './components/PowerMoveTips';
import { CalculationsModal } from './components/CalculationModal';
import { JoinPowerMove } from './JoinPowerMove';

const StyledPowerMoveChallengeHeading = styled(Heading2)`
  ${({ theme: { colors } }) => `
    color: ${colors.brand.ovo};
  `}
`;

export const PowerMove = () => {
  const tipsRef = createRef<HTMLDivElement>();
  const eligibilityCardRef = createRef<HTMLDivElement>();
  const apiFailRef = createRef<HTMLDivElement>();
  const { dispatch } = useAnalytics();
  const [decision] = useDecision(SHOW_POWER_MOVE);
  const challenges = usePowerMoveChallenges();
  const eligibility = usePowerMoveEligibility();

  const [hasEligibilityError, setHasEligibilityError] =
    useState<boolean>(false);
  const [isHHModalOpen, setIsHHModalOpen] = useState(false);
  const [isCalculationModalOpened, setCalculationModalOpen] = useState(false);
  const [showJoinedChallengeModal, setShowJoinedChallengeModal] =
    useState(false);
  const [challengeToOptOut, setChallengeToOptOut] =
    useState<MappedPowerMoveChallenge | null>(null);
  const [targetChallengeForRewards, setTargetChallengeForRewards] =
    useState<MappedPowerMoveChallenge | null>(null);

  const challengeSignupMutation = usePowerMoveChallengeSignupMutation(() =>
    setShowJoinedChallengeModal(true),
  );

  const joinedChallengeVersionId =
    challengeSignupMutation.data?.boltons?.[0]?.versionId ?? '';
  const challengeUserHasJustJoined = challenges.data.find(
    ch => ch.versionId === joinedChallengeVersionId,
  );
  const hasJoinedChallenge = challenges.joinedChallenges.length > 0;

  const dispatchErrors = (eligibility: UsePowerMoveEligibilityReturn): void => {
    if (
      !eligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      eligibility.updatingElecSmartMeterFrequencyToHalfHourly
    ) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_SWITCH_PENDING_ERROR,
        type: 'view',
      });
    }

    if (!eligibility.hasElecSmartMeter) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_SMART_METER_BOOKING_ERROR_VIEWED,
        type: 'view',
      });
    }

    if (
      !eligibility.hasElecHalfHourlySmartMeterReadsEnabled &&
      eligibility.hasElecSmartMeter &&
      !eligibility.updatingElecSmartMeterFrequencyToHalfHourly
    ) {
      dispatch({
        name: PATH_TO_ZERO.POWER_MOVE_NOT_ELIGIBLE_METER_VIEWED,
        type: 'view',
      });
    }
  };

  useEffect(() => {
    setHasEligibilityError(false);
  }, [eligibility]);

  useEffect(() => {
    if (!challenges.isLoading) {
      if (hasJoinedChallenge) {
        dispatch({
          name: PATH_TO_ZERO.POWER_MOVE_WELCOME_VIEWED,
          type: 'view',
          properties: {
            joinedChallenges: challenges.joinedChallenges,
          },
        });
      } else {
        dispatch({
          name: PATH_TO_ZERO.POWER_MOVE_JOIN_POWER_MOVE_VIEWED,
          type: 'view',
        });
      }
    }
    // dispatch should be stable across renders, but sadly it is not which leads to multiple events being dispatched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasJoinedChallenge, challenges.isLoading]);

  const onJoinChallengeHandler = (versionId: string) => {
    if (!eligibility.isEligible) {
      setHasEligibilityError(true);
      dispatchErrors(eligibility);
      eligibilityCardRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
      apiFailRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
      return;
    }

    challengeSignupMutation.mutate({ versionId });
  };

  const closeChallengeOptOutModal = () => {
    setChallengeToOptOut(null);
  };

  const onLeaveChallengeHandler = (challenge: MappedPowerMoveChallenge) => {
    setChallengeToOptOut(challenge);
  };

  const onShowMeTipsHandler = () => {
    tipsRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
  };

  const closeChallengeSuccessfullySignedUpModal = () => {
    setShowJoinedChallengeModal(false);
  };

  const closeChallengeSuccessfullySignedUpModalAndScrollToTips = () => {
    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_SHOW_ME_TIPS_CLICKED,
      type: 'click',
      properties: {
        challenge: challengeUserHasJustJoined?.displayName,
        challengeID: challengeUserHasJustJoined?.versionId,
      },
    });
    closeChallengeSuccessfullySignedUpModal();
    if (challengeUserHasJustJoined && !challengeUserHasJustJoined?.isUpcoming) {
      onShowMeTipsHandler();
    }
  };

  const onShowRewardsModalHandler = (challenge: MappedPowerMoveChallenge) => {
    dispatch({
      name: PATH_TO_ZERO.POWER_MOVE_HOW_REWARDS_ARE_CALCULATED_LINK_CLICKED,
      type: 'click',
    });
    setTargetChallengeForRewards(challenge);
  };

  const onCalculationModalClick = () => {
    setCalculationModalOpen(previousState => !previousState);
  };

  const onCloseRewardsModalHandler = () => {
    setTargetChallengeForRewards(null);
  };

  if (!decision.enabled) {
    return <PowerMoveErrorPage message={PWR_MV_FEATURE_OFF} />;
  }

  const currentChallenge = challenges.data.find(
    challenge => !challenge.isUpcoming,
  );

  const upcomingChallenges = challenges.data.filter(
    challenge => challenge.isUpcoming,
  );

  const renderChallenge = (challenge: MappedPowerMoveChallenge) => {
    const isMutatingThisChallenge =
      challengeSignupMutation.variables?.versionId === challenge.versionId;

    return (
      <PowerMoveMonthChallenge
        isLoading={challenges.isLoading}
        isLoadingOrUpdating={
          challenges.isLoading || challengeSignupMutation.isLoading
        }
        isJoiningChallenge={
          isMutatingThisChallenge && challengeSignupMutation.isLoading
        }
        isError={isMutatingThisChallenge && challengeSignupMutation.isError}
        challenge={challenge}
        tipsEnabled={!!decision.variables?.tipsEnabled}
        optOutEnabled={!!decision.variables?.optOutEnabled}
        onPressJoin={onJoinChallengeHandler}
        showRewardsModal={() => onShowRewardsModalHandler(challenge)}
        showCalculationModal={onCalculationModalClick}
        onPressLeave={() => onLeaveChallengeHandler(challenge)}
      />
    );
  };

  if (decision.variationKey === 'show_app_signup') {
    return <JoinPowerMove />;
  }

  return (
    <PageWithNavigation title="Power Move">
      <Margin top={8}>
        <>
          <Media query={breakpoints.mobileNav}>
            {isMobile =>
              isMobile && (
                <NavigationBreadCrumb
                  data-testid="back-to-path-to-zero"
                  href={'/'}
                />
              )
            }
          </Media>
          <PowerMoveHeroBanner isJoined={hasJoinedChallenge} />

          {!hasJoinedChallenge && (
            <>
              <SectionsHorizontalDivider />
              <PowerMoveDescriptionAccordion />
            </>
          )}

          {eligibility.isMetersApiError && (
            <Callout style={{ marginTop: 24 }} variant="error" ref={apiFailRef}>
              <P>
                Sorry, something went wrong. Refresh the page and try again.
              </P>
            </Callout>
          )}

          {!challenges.isError &&
            (!eligibility.isEligible || !eligibility.hasElecSmartMeter) && (
              <>
                <SectionsHorizontalDivider />
                <PowerMoveEligibilityCard
                  ref={eligibilityCardRef}
                  hasError={hasEligibilityError}
                  onUpdateToHHMeterReadsCTAClick={() => {
                    dispatch({
                      name: PATH_TO_ZERO.POWER_MOVE_NOT_ELIGIBLE_METER_CLICKED,
                      type: 'click',
                    });
                    setIsHHModalOpen(true);
                  }}
                />
              </>
            )}

          {!challenges.isError && (
            <>
              {currentChallenge && (
                <>
                  <SectionsHorizontalDivider />
                  <StyledPowerMoveChallengeHeading>
                    Current challenge
                  </StyledPowerMoveChallengeHeading>
                  {renderChallenge(currentChallenge)}
                </>
              )}

              {!!upcomingChallenges.length && (
                <>
                  <StyledPowerMoveChallengeHeading>
                    Upcoming challenge
                  </StyledPowerMoveChallengeHeading>
                  {upcomingChallenges.map((challenge, idx) => (
                    <React.Fragment key={challenge.versionId}>
                      {!!idx && <SectionsHorizontalDivider />}
                      {renderChallenge(challenge)}
                    </React.Fragment>
                  ))}
                </>
              )}

              {!!decision.variables?.tipsEnabled &&
                currentChallenge?.isJoined && (
                  <>
                    <SectionsHorizontalDivider />
                    <PowerMoveTips ref={tipsRef} />
                  </>
                )}
            </>
          )}

          {hasJoinedChallenge && (
            <>
              <SectionsHorizontalDivider />
              <PowerMoveDescriptionAccordion />
            </>
          )}

          {challenges.isError && (
            <Callout
              style={{ marginBottom: 24, marginTop: 24 }}
              variant="error"
            >
              <P>
                Sorry, something went wrong. Please refresh the page and try
                again.
              </P>
            </Callout>
          )}
        </>
      </Margin>
      <HowRewardsAreCalculatedModal
        isOpen={!!targetChallengeForRewards}
        onClose={onCloseRewardsModalHandler}
        challenge={targetChallengeForRewards}
      />
      <CalculationsModal
        isOpen={!!isCalculationModalOpened}
        onClose={onCalculationModalClick}
      />
      <PowerMoveChallengeSuccessfullySignedUpModal
        tipsEnabled={!!decision.variables?.tipsEnabled}
        isOpen={showJoinedChallengeModal}
        challenge={challengeUserHasJustJoined}
        onClose={closeChallengeSuccessfullySignedUpModal}
        onShowTips={closeChallengeSuccessfullySignedUpModalAndScrollToTips}
      />
      <NoHalfHourlyReadingsModal
        isOpen={isHHModalOpen}
        onClose={() => {
          dispatch({
            name: PATH_TO_ZERO.POWER_MOVE_HALF_HOURLY_MODAL_CLOSED,
            type: 'click',
          });
          setIsHHModalOpen(false);
        }}
        onCloseModal={() => setIsHHModalOpen(false)}
      />
      <PowerMoveChallengeOptOutModal
        isOpen={!!challengeToOptOut}
        challenge={challengeToOptOut}
        onClose={closeChallengeOptOutModal}
      />
    </PageWithNavigation>
  );
};
