import React from 'react';
import { PageWithNavigation } from '@/src/components';
import { Margin } from '@ovotech/nebula';

import {
  StyledHeading1,
  StyledHeading3,
  StyledSubtitle,
  StyledTagline,
  StyledDescription,
  StyledStack,
  StyledPowerMoveApps,
  StyledCard,
  StyledImage,
  PowerMoveBenefit,
  StyledLoginToJoinButton,
} from './components/JoinPowerMoveStyles';

import { ReactComponent as PowerMoveLightning } from '@/src/resources/power-move-lightning.svg';
import { ReactComponent as EcoIcon } from '@/src/resources/eco.svg';
import { ReactComponent as ElecFlashIcon } from '@/src/resources/electricity-flash.svg';
import { ReactComponent as WalletIcon } from '@/src/resources/wallet.svg';
import { ReactComponent as AppStoreQR } from './assets/app-store-qr.svg';
import { ReactComponent as PlayStoreQR } from './assets/play-store-qr.svg';
import { ReactComponent as AppStoreBadge } from './assets/app-store-badge.svg';
import { ReactComponent as PlayStoreBadge } from './assets/play-store-badge.svg';
import iPhoneMock from './assets/iPhonePM.png';

const URL_STORE = {
  apple: 'https://apps.apple.com/gb/app/ovo-energy/id626316021',
  google: 'https://play.google.com/store/apps/details?id=com.ovo.energy',
  deepLink: 'https://account.ovoenergy.com/power-move/signup',
};

export const JoinPowerMove = () => {
  return (
    <PageWithNavigation title="Power Move">
      <Margin top={8}>
        <StyledCard>
          <StyledStack spaceBetween={2}>
            <StyledHeading1>Power Move.</StyledHeading1>
            <PowerMoveLightning />
          </StyledStack>
          <StyledSubtitle>Now in the OVO app</StyledSubtitle>
          <StyledTagline>
            Join the challenge to use electricity at greener times.
          </StyledTagline>

          <Margin top={8}>
            <PowerMoveBenefit
              icon={<EcoIcon />}
              title="Earn monthly credit"
              description="You’ll get money off your energy bills and help take pressure
                  off the grid. Win-win!"
            />
            <PowerMoveBenefit
              icon={<ElecFlashIcon />}
              title="Track your progress"
              description="Be in the know with weekly updates on your Power Move via the
                  app."
            />
            <PowerMoveBenefit
              icon={<WalletIcon />}
              title="See your rewards"
              description="Keep an eye on how much credit you’re on track to earn each
                  month."
            />
          </Margin>

          <Margin top={4}>
            <StyledHeading3>Download the app to join</StyledHeading3>
            <StyledPowerMoveApps>
              <img src={iPhoneMock} alt="iPhone Mock" />
              <div className="power__move__apps">
                <div className="power-move__app">
                  <AppStoreQR />
                  <button onClick={() => window.open(URL_STORE.apple)}>
                    <AppStoreBadge />
                  </button>
                </div>
                <div className="power-move__app">
                  <PlayStoreQR />
                  <button onClick={() => window.open(URL_STORE.google)}>
                    <PlayStoreBadge />
                  </button>
                </div>
              </div>
            </StyledPowerMoveApps>

            <StyledHeading3>Already got the app?</StyledHeading3>
            <StyledDescription>
              You might need to update to the latest app version. Check your app
              store.
            </StyledDescription>
            <StyledLoginToJoinButton iconRight="off" href={URL_STORE.deepLink}>
              Log in to join
            </StyledLoginToJoinButton>
          </Margin>

          <StyledImage src={iPhoneMock} alt="iPhone Mock" />
        </StyledCard>
      </Margin>
    </PageWithNavigation>
  );
};
