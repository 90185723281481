import { Card, Checkbox, Ul } from '@ovotech/nebula';
import styled from 'styled-components';

export const LoadingStyledCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.3rem;
  svg.loading-icons {
    margin-right: 2px;
    margin-left: 2px;
    @media only screen and (min-width: 550px) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  @media only screen and (min-width: 1025px) {
    max-width: 732px;
  }
`;

export const StyledTermsCheckbox = styled(Checkbox)`
  position: absolute;
  margin-top: 100px;
`;

export const StyledUl = styled(Ul)`
  list-style-type: none;
  margin-left: 0;
`;

export const PromoContainer = styled.div`
  display: inline-flex;
  gap: 10px;
`;

export const ButtonGroupWrapper = styled.div`
  ${({ theme: { mediaQueries } }) => `
    margin-top: auto;
    div {
      display: flex;
      flex-direction: column-reverse;
      a {
        margin-bottom: 2rem;
      }
    }

${mediaQueries.mediumAndUp} {
  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    a {
      height: 50%;
    }
  }
};
`}
`;

export const Divider = styled.div`
  ${({ theme: { colors } }) => `
    border-top: 1px solid ${colors.brand.grey.base};
  `}
  margin-bottom: 3.2rem;
`;
