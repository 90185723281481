import {
  Heading3,
  P,
  Card,
  Heading4,
  Ul,
  Li,
  Strong,
  SecondaryCTALink,
  CTALink,
} from '@ovotech/nebula';
import { AnalyticsClick } from '@ovotech/ui-tools';
import React from 'react';
import { Link } from 'react-router-dom-v5';
import {
  StyledNewsPanel,
  StyledNewsText,
  StyledImageMobile,
  StyledNewsImage,
} from './EVNews.styled';
import { useAnytimeBoltonPricingQuery } from '@/src/api';
import { EV } from '@/src/constants/analytics';
import {
  DRIVE_ANYTIME,
  ALLOW_ANYTIME_SIGNUP,
} from '@/src/constants/feature-flags';
import { ROUTE_EV, ROUTE_EV_ANYTIME } from '@/src/constants/routes';
import { OptimizelyFeature } from '@/src/utils/optimizely';
import EVAnytimeMobile from '@/src/resources/anytime-offer-mobile.jpg';
import EVAnytime from '@/src/resources/anytime-offer.png';
import { useChargeAnytimeBoltonState } from '@/src/pages/AnytimeEligibility/hooks/useChargeAnytimeBoltonState';

export const EVNewsAndOffersTile = () => {
  const { anytimeBoltonActive } = useChargeAnytimeBoltonState();
  const anytimeBoltonPricing = useAnytimeBoltonPricingQuery();

  const getContent = () => {
    if (anytimeBoltonPricing.isError)
      return 'Smart charge and save with our super-low Charge Anytime rate';
    if (anytimeBoltonPricing.isLoading)
      return (
        <>
          Enjoy a super-low EV charging rate of only <Strong>XXp/kWh</Strong>{' '}
          when you smart charge your car
        </>
      );

    if (anytimeBoltonPricing.data)
      return (
        <>
          Enjoy a super-low EV charging rate of only{' '}
          <Strong>{anytimeBoltonPricing.data.formattedPence}</Strong> when you
          smart charge your car
        </>
      );
  };

  return (
    <>
      <Heading3>EV news and offers</Heading3>
      <P>
        Learn all the latest about electric cars and find out how to save more
        with our amazing offers.
      </P>
      <OptimizelyFeature feature={DRIVE_ANYTIME}>
        {isEnabled => {
          return (
            isEnabled &&
            !anytimeBoltonActive && (
              <StyledNewsPanel as={Card}>
                <StyledNewsImage alt="" src={EVAnytime} aria-hidden="true" />
                <StyledImageMobile
                  alt=""
                  src={EVAnytimeMobile}
                  aria-hidden="true"
                />

                <StyledNewsText>
                  <Heading4>
                    Charge Anytime: our electrifying new add-on for EV drivers
                  </Heading4>
                  <Ul>
                    <Li>{getContent()}</Li>
                    <Li>
                      Get insights about your charging habits and tips on
                      cutting carbon
                    </Li>
                    <Li>
                      If you need to boost charge your EV, you'll be charged
                      your standard energy rate
                    </Li>
                  </Ul>
                  <P>
                    <OptimizelyFeature feature={ALLOW_ANYTIME_SIGNUP}>
                      {isEnabled =>
                        isEnabled ? (
                          <AnalyticsClick
                            name={
                              EV.ELIGIBILITY
                                .EVS_OVO_CHARGE_ANYTIME_GO_TO_ELIGIBILITY_PAGE_BUTTON_CLICKED
                            }
                          >
                            <SecondaryCTALink
                              data-event-name={
                                'EVS - EVS OVO Drive Anytime Navigate to Eligibility page button clicked'
                              }
                              to={`${ROUTE_EV_ANYTIME}/eligibility`}
                              as={Link}
                            >
                              Check I'm eligible
                            </SecondaryCTALink>
                          </AnalyticsClick>
                        ) : (
                          <Strong>
                            Charge Anytime will be available from February 2023
                          </Strong>
                        )
                      }
                    </OptimizelyFeature>
                  </P>
                  <P>
                    <AnalyticsClick
                      name={EV.EVS_OVO_DRIVE_GO_TO_NEWS_PAGE_BUTTON_CLICKED}
                    >
                      <CTALink
                        variant="primary"
                        iconRight="chevron-right"
                        as={Link}
                        to={`${ROUTE_EV}/news-and-offers`}
                        data-testid="navigate-to-news-and-offers"
                      >
                        See all EV news and offers
                      </CTALink>
                    </AnalyticsClick>
                  </P>
                </StyledNewsText>
              </StyledNewsPanel>
            )
          );
        }}
      </OptimizelyFeature>
    </>
  );
};
