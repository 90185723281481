import React, { useState } from 'react';
import { PageWithNavigation } from '@/src/components';
import { ALLOW_ANYTIME_SIGNUP } from '@/src/constants/feature-flags';
import { useFeature } from '@/src/utils/optimizely';
import { EVAnytimeStepProps } from './types';
import { VehicleAndHomeSetupQuestions } from './pages/steps/VehicleAndHomeSetupQuestions';
import { PageHeader } from './components/PageHeader';
import { JourneyUnavailable } from './components/JourneyUnavailable';
import { SmartChargerQuestions } from './pages/steps/SmartChargerQuestions';
import { FalloutOrRedirectToConsent } from './pages/steps/FalloutOrRedirectToConsent';

const renderPageContent = (
  PageStep: number,
  moveNext: () => void,
  movePrevious: () => void,
  movePreviousTwo: () => void,
) => {
  const props: EVAnytimeStepProps = { moveNext, movePrevious, movePreviousTwo };
  const steps = [
    <SmartChargerQuestions {...props} key={1} />,
    <VehicleAndHomeSetupQuestions {...props} key={2} />,
    <FalloutOrRedirectToConsent {...props} key={3} />,
  ];

  return steps[PageStep - 1];
};

export const AnytimeEligibility = () => {
  const [pageStep, setPageStep] = useState(1);
  const [allowAnytimeSignUp] = useFeature(ALLOW_ANYTIME_SIGNUP);

  const moveNext = () => {
    setPageStep(previousState => previousState + 1);
  };

  const movePrevious = () => {
    setPageStep(previousState => previousState - 1);
  };

  const movePreviousTwo = () => {
    setPageStep(previousState => previousState - 2);
  };

  return (
    <PageWithNavigation title="EV Charge Anytime - Eligibility">
      {allowAnytimeSignUp ? (
        <>
          <PageHeader
            title="Check your setup"
            showDescription={false}
            testId="check-eligibility-header"
          />
          {renderPageContent(pageStep, moveNext, movePrevious, movePreviousTwo)}
        </>
      ) : (
        <JourneyUnavailable />
      )}
    </PageWithNavigation>
  );
};
