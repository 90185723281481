import { Heading4, P, TextLink } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom-v5';
import { FootnoteProvider } from '@/src/components';
import { State } from '@/src/types/State';
import {
  Container,
  Reason,
  Reasons,
  StyledSubtitle,
  StyledTitle,
} from './FalloutOrRedirectToConsent.styled';
import { AnalyticsClick, useAnalytics } from '@ovotech/ui-tools';
import { EV } from '@/src/constants/analytics';
import { SMART_BOOKING_URL } from '@/src/constants/endpoints';
import {
  EV_ANYTIME_SMART_CHARGER_LINK,
  SWITCH_TO_OVO_ELECTRICITY,
} from '@/src/constants/links';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { useChargeAnytimeContext } from '@monovo/ev-anytime';
import { SmartMeterReadFrequency } from '@/src/pages/AnytimeEligibility/components/SmartMeterReadFrequency';
import { SolarPanelsIncompatible } from '@/src/pages/AnytimeEligibility/components/SolarPanelsIncompatible';
import { ReturnToHomePage } from '@/src/components/ReturnToHomePage/ReturnToHomePage';
import { TechnicalGlitch } from '@/src/pages/AnytimeEligibility/components/TechnicalGlitch';
import { ROUTE_EV_ANYTIME } from '@/src/constants/routes';
import { routes } from '@/src/pages/AnytimeInlife/OvoDriveAnytime';

const Loading = () => {
  return <SkeletonCard data-testid="loading-screen" />;
};

const ChooseYourCharger = () => (
  <AnalyticsClick
    name={
      EV.ELIGIBILITY
        .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_CHARGER_CLICKED
    }
  >
    <TextLink
      data-event-name={
        'Click-EVS - EVS OVO Drive Anytime Smart charger offer button clicked [Orion Web]'
      }
      href={EV_ANYTIME_SMART_CHARGER_LINK}
      opensInNewWindow
    >
      Choose your EV charger today
    </TextLink>
  </AnalyticsClick>
);

export const FalloutOrRedirectToConsent = () => {
  const { dispatch: analyticsDispatch } = useAnalytics();

  const history = useHistory();

  const { selectedAccountId: accountId } = useSelector(
    (state: State) => state.user,
  );

  const {
    eligibility,
    isError,
    isFetching,
    smartCharger,
    make,
    model,
    year,
    hasSolarPanels,
    hasInternetAccess,
    hasSmartMeter,
    ineligibleInvalidMeterReadFrequency,
    ineligibleOnlyGas,
  } = useChargeAnytimeContext();

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: eligibility.isEligible
        ? EV.ELIGIBILITY.EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_ELIGIBILITY_VIEWED
        : EV.ELIGIBILITY
            .EVS_OVO_CHARGE_ANYTIME_CONFIRMATION_INELIGIBILITY_VIEWED,
      properties: {
        smartChargerSelection: smartCharger,
        make,
        model,
        year,
        eligibleVehicle: !eligibility.incompatibleCar,
        hasSolarPanels,
        hasInternetAccess,
        hasSmartMeter,
        hasValidMeterReadFrequency: !ineligibleInvalidMeterReadFrequency,
        hasOvoElectricity: !ineligibleOnlyGas,
      },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    analyticsDispatch({
      type: 'view',
      name: eligibility.isEligible
        ? 'EVS - EVS OVO Charge Anytime D2V Outcome eligible Viewed'
        : 'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Viewed',
      properties: {
        smartChargerSelection: smartCharger,
        carMake: make,
        carModel: model,
        carYear: year,
        internetAccess: hasInternetAccess,
        solarPanels: hasSolarPanels,
      },
    });
    // `dispatch` method is not memoized, and it causes useless calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <TechnicalGlitch />;
  }

  const renderConfirmation = () => {
    if (eligibility.isEligible) {
      history.push(ROUTE_EV_ANYTIME + routes.consent);
    }

    const smartMeterBookingRoute = `${SMART_BOOKING_URL}?accountId=${accountId}&utm_source=anytimeEligibility&utm_medium=MyOVO&utm_campaign=smart-meter-booking`;

    return (
      <Container>
        <Reasons>
          {eligibility.invalidOther ? (
            <Reason data-testid="invalid-other">
              <StyledTitle>
                Sorry, we can’t bring you Charge Anytime at the moment.
              </StyledTitle>
              <P>
                We’re working hard to make Charge Anytime available to more
                customers.
              </P>
              <P>
                Please check back soon to see if Charge Anytime is available for
                you.
              </P>
              <ReturnToHomePage />
            </Reason>
          ) : (
            <Reason>
              <StyledTitle>Your setup isn’t compatible right now.</StyledTitle>
              <StyledSubtitle>
                {eligibility.numberOfReasons && eligibility.numberOfReasons > 1
                  ? `Here are ${eligibility.numberOfReasons} things you can do to get Charge Anytime`
                  : 'Here’s what you can do to get Charge Anytime'}
              </StyledSubtitle>
            </Reason>
          )}
          {eligibility?.notSmartMeter && (
            <Reason data-testid="smart-meter-offer">
              <Heading4>Save money with a smart meter</Heading4>
              <P>
                Charge Anytime needs a smart meter that sends half-hourly
                readings to connect your EV or charger to your OVO account, and
                the grid. If you don’t already have an electricity smart meter,
                we’ll install one for free.
              </P>
              <AnalyticsClick
                name={
                  EV.ELIGIBILITY
                    .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_SMART_METER_CLICKED
                }
              >
                <TextLink
                  data-event-name={
                    'EVS - EVS OVO Charge Anytime D2V Outcome Ineligible Get Smart Meter clicked'
                  }
                  opensInNewWindow
                  href={smartMeterBookingRoute}
                >
                  Get a smart meter
                </TextLink>
              </AnalyticsClick>
            </Reason>
          )}
          {eligibility?.onlyGasSupply && (
            <Reason data-testid={'gas-only'}>
              <Heading4>Get electricity with OVO</Heading4>
              <P>
                At the moment you’re on a gas-only plan. Switch to OVO for your
                electricity and add Charge Anytime to your plan for free.
              </P>
              <AnalyticsClick
                name={
                  EV.ELIGIBILITY
                    .EVS_OVO_CHARGE_ANYTIME_OUTCOME_INELIGIBLE_GET_ELECTRICITY_CLICKED
                }
              >
                <TextLink
                  data-event-name={
                    'EVS - EVS OVO Drive Anytime Electricity offer button clicked'
                  }
                  href={SWITCH_TO_OVO_ELECTRICITY}
                  opensInNewWindow
                >
                  Get an electricity quote
                </TextLink>
              </AnalyticsClick>
            </Reason>
          )}
          {eligibility?.doesntChargeEvAtHome && (
            <>
              <Reason data-testid="smart-charger-offer">
                <Heading4>You don't charge your EV at home</Heading4>
                <P>
                  Make the most of your EV with the next-generation Indra Smart
                  PRO or Ohme chargers – now available at an exclusive price for
                  OVO members.
                </P>
                <ChooseYourCharger />
              </Reason>
            </>
          )}
          {eligibility?.incompatibleCar && (
            <Reason data-testid="incompatible-car">
              <Heading4>Connect through a smart EV charger</Heading4>
              <P>
                Your EV isn’t compatible right now, but you could still get
                Charge Anytime through a compatible smart EV charger – and we
                can install it for you.
              </P>
              <ChooseYourCharger />
            </Reason>
          )}
          {eligibility?.hasSolarPanels && (
            <Reason data-testid="has-solar-panels">
              <SolarPanelsIncompatible />
            </Reason>
          )}
          {eligibility?.hasNoInternetAccess && (
            <Reason data-testid="has-no-internet-access">
              <Heading4>
                Make sure your EV connects to the internet while smart charging
              </Heading4>
              <P>
                If your EV has an internet connection, make sure you park where
                you can get a clear signal.
              </P>
              <P>
                If your EV doesn’t have an internet connection, you could still
                get Charge Anytime through one of our compatible smart EV
                chargers – and we’ll install it too.
              </P>
              <ChooseYourCharger />
            </Reason>
          )}
          {eligibility?.invalidMeterReadFrequency && (
            <Reason data-testid="half-hourly-reads-offer">
              <SmartMeterReadFrequency />
            </Reason>
          )}
        </Reasons>
      </Container>
    );
  };

  return (
    <FootnoteProvider markerType="number">
      <div>{renderConfirmation()}</div>
    </FootnoteProvider>
  );
};
