import {
  Card,
  ErrorNotification,
  P,
  Heading4,
  Heading1,
  CTALink,
} from '@ovotech/nebula';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-v5';
import {
  StyledDiv,
  StyledHeading2,
  StyledCentered,
  StyledHeadingContainer,
  StyledInlineFlex,
  StyledCTAContainer,
  StyledMarginHeading3,
  StyledMarginP,
} from '../EVInsights/EVInsights.styled';
import {
  StyledMiniCardImage,
  StyledTextUnderImage,
} from '../EVSavings/EVSavings.styled';
import DirectDebit from '@/src/resources/direct-debit-icon.svg';
import { useAnytimeCreditsQuery } from '@/src/api';
import { SkeletonCard } from '@/src/components/SkeletonLoading/SkeletonCard';
import { ROUTE_EV_ANYTIME } from '@/src/constants/routes';
import { State } from '@/src/types/State';
import { useChargeAnytimeBoltonState } from '../../../AnytimeEligibility/hooks/useChargeAnytimeBoltonState';
import { useAccountContext } from '@ovotech/ui-tools';

const Loading = () => {
  return <SkeletonCard />;
};

export const EVAnytimeTile = () => {
  const { connection } = useSelector(({ evInlife }: State) => evInlife);
  const currentDateObj = new Date();
  const anytimeCurrentDate = format(currentDateObj, 'YYYY/MM');
  const [anytimeCurrentDateYear, anytimeCurrentDateMonth] =
    anytimeCurrentDate.split('/');
  const { accountId } = useAccountContext();

  const { anytimeBoltonActive, isFetching, anytimeBolton } =
    useChargeAnytimeBoltonState();

  const anytimeQueryRunCondition =
    anytimeBoltonActive &&
    Boolean(connection) &&
    Boolean(anytimeBolton?.activeFrom);

  const anytimeResponse = useAnytimeCreditsQuery(
    accountId,
    anytimeBolton?.activeFrom,
    anytimeCurrentDateYear,
    anytimeCurrentDateMonth,
    anytimeQueryRunCondition,
  );

  const anytimeNoDataErrorCondition =
    anytimeResponse.status === 'no-data' &&
    !anytimeQueryRunCondition &&
    !isFetching;

  const anytimeSavings =
    anytimeResponse.status === 'data' ? anytimeResponse.data : null;

  const currentMonthSavings =
    anytimeSavings?.currentMonth.anytimeCreditGBP || 0;
  const anytimeError =
    anytimeResponse.status === 'error' || anytimeNoDataErrorCondition;

  if (anytimeResponse.isFetching) {
    return (
      <>
        <StyledMarginHeading3>Charge Anytime</StyledMarginHeading3>
        <StyledMarginP>See what Charge Anytime is saving you.</StyledMarginP>
        <Loading />
      </>
    );
  }

  return (
    <>
      <StyledMarginHeading3>Charge Anytime</StyledMarginHeading3>
      <StyledMarginP>See what Charge Anytime is saving you.</StyledMarginP>
      <Card>
        {anytimeError ? (
          <ErrorNotification
            data-testid="anytime-error"
            id="AnytimeError"
            title="There’s a slight problem"
          >
            <P>
              We’re working hard to fix it. But in the meantime, please try
              refreshing the page.
            </P>
          </ErrorNotification>
        ) : (
          <StyledDiv>
            <StyledCentered>
              <StyledTextUnderImage>
                <Heading4>Saved so far this month</Heading4>
              </StyledTextUnderImage>
              <StyledInlineFlex>
                <StyledMiniCardImage
                  src={DirectDebit}
                  alt="direct debit icon"
                  center
                />
                <StyledHeadingContainer>
                  <StyledHeading2 as={Heading1}>
                    £
                    <span data-testid="current-month-savings">
                      {currentMonthSavings}
                    </span>
                  </StyledHeading2>
                </StyledHeadingContainer>
              </StyledInlineFlex>
            </StyledCentered>
            <StyledCTAContainer>
              <CTALink
                variant="primary"
                iconRight="chevron-right"
                as={Link}
                to={ROUTE_EV_ANYTIME}
                data-testid="navigate-to-anytime"
              >
                See the breakdown
              </CTALink>
            </StyledCTAContainer>
          </StyledDiv>
        )}
      </Card>
    </>
  );
};
